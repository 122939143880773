import request from '@/utils/request'

// 我的消息提示数量
export function listByUser(params) {
    return request({
        url: '/lowcode/sys/annountCement/listByUser',
        method: 'get',
        params
    })
}
// 我的消息列表
export function getMyAnnouncementSend(params) {
    return request({
        url: '/lowcode/sys/sysAnnouncementSend/getMyAnnouncementSend',
        method: 'get',
        params
    })
}
export function editByAnntIdAndUserId(data) {
    return request({
        url: '/lowcode/sys/sysAnnouncementSend/editByAnntIdAndUserId',
        method: 'put',
        data
    })
}
// 我的报名活动
export function getMyApply(params) {
    return request({
        url: '/lowcode/bizOpportunityActivity/myActivity',
        method: 'get',
        params
    })
}
// 我的报名课程
export function getMyCourse(params) {  
    return request({
        url: '/lowcode/trainingCourses/myCourses',
        method: 'get',
        params
    })
}
// 我的文章留言
export function getMyleave(params) {  
    return request({
        url: '/lowcode/contentManagement/articleMessage/personalArticlePage',
        method: 'get',
        params
    })
}

export function soldierApprove(params) {  
    console.log(params);
    return request({
        url: '/lowcode/jctOther/veterans',
        method: 'get',
        params
    })
}

export function SoldiersCertification (params) {  
    return request({
        url: '/lowcode/militaryCertification/info',
        method: 'get',
        params
    })
}
export function getUserInfo (params) {  
    return request({
        url: '/lowcode/jctUser/userInfo',
        method: 'get',
        params
    })
}
export function getEditUserInfo (params) {  
    return request({
        url: '/lowcode/sys/user/editDetail',
        method: 'get',
        params
    })
}
export function postUserInfo (data) {  
    return request({
        url: '/lowcode/sys/user/editLoginUser',
        method: 'post',
        data
    })
}
export function UploadingImg(data) {
    return request({
        url: `/lowcode/sys/common/upload`,
        method: 'POST',
        data
    })
}
export function serviceMessage(params) {
    return request({
        url: `/lowcode/sys/message/customReplay/getReplay`,
        method: 'get',
        params
    })
}