import Vue from 'vue'
import App from './App.vue'

// 移动端适配
import 'lib-flexible/flexible.js';
//全局样式
// import 'normalize.css';
import '@/assets/css/base.css'
// vant组件
import Vant from 'vant'
import 'vant/lib/index.css'
Vue.use(Vant)
// 路由
import router from './router';
// vuex
import store from './store';
// 加载 dayjs 初始化配置
import './common/dayjs'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
