<template>
  <div id="app">
    <keep-alive include="dynamicList">
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
import { getItem } from '@/utils/auth'
import { login } from '@/api/login'
import { setItem } from '@/utils/auth'
export default {
  name: 'App',
  components: {},
  created () {
    this.getLongin()
    this.getUiStyle()

    // window.addEventListener('popstate', async () => {
    //   if (
    //     ['/', '/view/home', '/401', '/404', '500'].indexOf(this.$route.path) !==
    //     -1
    //   ) {
    //     const res = await this.windowClose()
    //   }
    // })
  },
  mounted () {
    this.watchApp()
  },
  methods: {
    getUiStyle () {
      let that = this
      ZWJSBridge.getUiStyle().then((res) => {
        switch (res.uiStyle) {
          case 'elder':
            that.$store.commit('user/alterElder', true)
            break
          default:
            that.$store.commit('user/alterElder', false)
        }
      })
    },
    watchApp () {
      let thst = this
      window.addEventListener(
        'popstate',
        function (event) {
          if (thst.$route.fullPath == '/home') {

            ZWJSBridge.onReady(() => {

              ZWJSBridge.close() //这个是浙里办内部的api，调用close()接口关闭通过openLink打开的页面
                .then((result) => {
                  console.log(result)
                })
                .catch((error) => {
                  console.log(error)
                })
            })
          }
        },

        false
      )
    },
    async getLongin () {
      console.log(window.ZWJSBridge)
      await login({
        username: 'admin',
        password: 'jctadmin123...'
      }).then((res) => {
        setItem('Admin-Token', res.result.token)
        this.$store.dispatch('user/getUserInfo')
      })
      this.$router.push('/home')
    }
  }
}
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
</style>
