import request from '@/utils/request'

export function login(data) {
    return request({
        url: '/lowcode/sys/mLogin',
        method: 'post',
        data
    })
}

// export function login(params) {
//     return request({
//         url: '/lowcode/thirdLogin/callback',
//         method: 'get',
//         params
//     })
// }