import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import { getItem } from '@/utils/auth'
Vue.use(Router)


const router = new Router({
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
    routes: [
        // 底部导航
        {
            path: '/',
            name: 'main',
            component: () => import('../views/layout'),
            redirect: '/home',
            children: [
                {
                    path: '/home',
                    component: () => import('../views/home'),
                }
            ],
            meta: {
                title: '首页'
            }
        },
        {
            path: '/affiche',
            name: 'affiche',
            component: () => import('@/views/affiche'),
            meta: {
                title: '公告',
            }
        },
        {
            path: '/afficheDetails',
            name: 'afficheDetails',
            component: () => import('@/views/affiche/afficheDetails'),
            meta: {
                title: '公告详情',
            }
        },

        {
            path: '/jun-chuang',
            name: 'jun-chuang',
            component: () => import('../views/jun-chuang'),
            meta: {
                title: '军创圈',
            }
        },
        {
            path: '/dynamicDetails/:id?',
            name: 'dynamicDetails',
            component: () => import('@/views/jun-chuang/dynamic-details.vue'),
            meta: {
                title: '动态详情页'
            }
        },
        {
            path: '/hotDynamic/:id?/:name?',
            name: 'hotDynamic',
            component: () => import('@/views/jun-chuang/hotDynamic.vue'),
            meta: {
                title: '热门动态页'
            }
        },
        {
            path: '/me',
            name: 'me',
            component: () => import('../views/me'),
            meta: {
                title: '我的'
            }
        },
        // 课程页
        {
            path: '/curriculum/:type?',
            name: 'curriculum',
            component: () => import('@/views/curriculum'),
            meta: {
                title: '课程列表'
            }
        },
        {
            path: '/curriculumdetails/:id?',
            name: 'curriculum-details',
            component: () => import('@/views/curriculum/curriculum-details.vue'),
            meta: {
                title: '课程详情页'
            }
        },

        // 文章页
        {
            path: '/comprehensive/:id',
            name: 'comprehensive',
            component: () => import('../views/comprehensive'),
            meta: {
                title: '综合服务'
            }
        },
        {
            path: '/comprehensive/details/:id',
            name: 'composite-details',
            component: () => import('../views/composite-details/index.vue'),
            meta: {
                title: '综合详情'
            }
        },
        // 活动页
        {
            path: '/home/activity/activity-list/:id?',
            name: 'activity-list',
            component: () => import('../views/home/activity/activity-list.vue'),
            meta: {
                title: '活动列表页'
            }
        },
        {
            path: '/home/activity/activity-listDetails/:id',
            name: 'activity-listDetails',
            component: () => import('../views/home/activity/activity-listDetails.vue'),
            meta: {
                title: '活动列表详情页'
            }
        },
        // 我的
        {
            path: '/me/my-news',
            name: 'my-news',
            component: () => import('../views/me/my-news.vue'),
            meta: {
                title: '我的信息'
            },
        },
        {
            path: '/me/like',
            name: 'my-like',
            component: () => import('@/views/me/my-like.vue'),
            meta: {
                title: '我的点赞'
            },
        },
        // 军人认证
        {
            path: '/me/approve',
            name: 'me-approve',
            component: () => import('../views/me/my-approve.vue'),
            meta: {
                title: '军人认证'
            },
        },
        // 我的资料
        {
            path: '/me/info',
            name: 'me-info',
            component: () => import('../views/me/my-info.vue'),
            meta: {
                title: '我的资料'
            },
        },
        // 我的报名
        {
            path: '/me/my-signUp',
            name: 'my-signUp',
            component: () => import('../views/me/my-signUp.vue'),
            meta: {
                title: '我的报名'
            },
        },
         // 咨询问题
         {
            path: '/me/my-issue',
            name: 'my-issue',
            component: () => import('../views/me/my-issue.vue'),
            meta: {
                title: '咨询问题'
            },
        },
         // 智能客服
         {
            path: '/me/my-service',
            name: 'my-service',
            component: () => import('../views/me/my-service.vue'),
            meta: {
                title: '智能客服'
            },
        },
        // 我的留言
        {
            path: '/me/my-leave',
            name: 'my-leave ',
            component: () => import('../views/me/my-leave.vue'),
            meta: {
                title: '我的留言'
            },
        },
        // 签到页面
        {
            path: '/courseSignin',
            name: 'courseSignin',
            component: () => import('../views/signin/courseSignin.vue'),

        },
        {
            path: '/activitySignin',
            name: 'activitySignin',
            component: () => import('../views/signin/activitySignin.vue'),

        },

        {
            path: '/await',
            name: 'await',
            component: () => import('../components/await.vue'),

        },
    ],
})

export default router

// // //获取原型对象上的push函数
const originalPush = Router.prototype.push
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)

}
// // // 前置导航守卫
router.beforeEach((to, from, next) => {
    if (!getItem('soldierApprove')) {
        if (to.path === '/me/approve') {
            next();
        } else {
            next(`/me/approve?goto=${to.path}`)
        }
    } else {

        next()
    }
    // window.history.pushState({url:'#'},null,'#')
    // window.history.forward(1)


    // if (getItem('Admin-Token')) {
    //     if (!getItem('soldierApprove')) {
    //         if (to.path === '/me/approve') {
    //             next();
    //         } else {
    //             // next(`/me/approve`)
    //             next({ path: '/me/approve', replace: true })
    //         }
    //     } else {
    //         next()
    //     }
    // } else {
    //     if (to.path === '/await') {
    //         next()
    //     } else {

    //         window.location.replace( `https://puser.zjzwfw.gov.cn/sso/mobile.do?action=oauth&scope=1&servicecode=0ec8f1729de447d5bcc0cacef06cbff9&goto=`+encodeURIComponent(to.fullPath)) ;
    //         console.log(to.fullPath);
    //     }
    // }
})

