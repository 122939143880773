import { listByUser, getUserInfo } from '@/api/me'
import { getItem, setItem } from '@/utils/auth'
import router from "@/router/index"
import { SoldiersCertification } from '@/api/me'

// 状态
const state = {
  userNews: 0, // 用户消息数量
  userInfo: getItem('userInfo') || {},
  soldierApprove: getItem('soldierApprove') || null,
  elderModel: false
};
const mutations = {
  setUserNews(state, userNews) {
    state.userNews = userNews;
    setItem('userNews', state.userNews)
  },
  setUserInfo(state, userInfo) {
    state.userInfo = userInfo;
    setItem('userInfo', state.userInfo)

  },
  alterElder(state, val) {
    state.elderModel = val;
  },
  setSoldierApprove(state, val) {
    state.soldierApprove = val;
    setItem('soldierApprove', state.soldierApprove)
    console.log(getItem('soldierApprove'));
  }
};
const actions = {
  async getlistByUser(context, data) {
    const result = await listByUser()
    context.commit("setUserNews", result.result.sysMsgTotal);
  },
  async getUserInfo(context, data) {
    const result = await getUserInfo()
    console.log(result);
    context.commit("setUserInfo", result.result);
    SoldiersCertification({
      userId: getItem('userInfo').id
    }).then((res) => {
      if (res.code == 200) {
        context.commit('setSoldierApprove', res.result)
        if (router.currentRoute.query.sp) {
          router.replace(router.currentRoute.query.sp)
         
        } else {
          router.replace('/home')
       
        }
      }
    })

  },
  async getUserInfo1(context, data) {
    const result = await getUserInfo()
    console.log(result);
    context.commit("setUserInfo", result.result);


  }
};
export default {
  namespaced: true,
  state,
  mutations,
  actions
};
