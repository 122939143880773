import axios from 'axios'

import { getItem } from '@/utils/auth'

// import JSONBig from 'json-bigint'

const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API,
  baseURL: 'http://junh5.answer12.com',
  // baseURL: 'https://cjzsjctpc.hzxh.gov.cn',
  // baseURL: 'http://192.168.0.138:45210',
  timeout: 30000,
  // 自定义后端返回的原始数据
  // 解决后端返回数据中大数字问题
  // transformResponse: [function (data) {
  //   try {
  //     return JSONBig.parse(data)
  //   } catch (err) {
  //     return data
  //   }

  // }]

})


service.interceptors.request.use(
  config => {

    if (getItem('Admin-Token')) {

      config.headers['X-Access-Token'] = getItem('Admin-Token')
    }
    return config
  },
  error => {

    console.log(error)
    return Promise.reject(error)
  }
)

service.interceptors.response.use(

  response => {
    const res = response.data
    return res
  },
  error => {

    return Promise.reject(error)
  }
)

export default service
